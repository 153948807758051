@import url("https://cdn.syncfusion.com/ej2/material.css");

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
  margin: 1%;
  border-radius: 25px;
  height: 97%;
}
.flex.relative.dark\:bg-main-dark-bg {
  background-color: #eae9ee;
}
.nav-item,
/* .navbar {
	z-index: 10000;
} */
@media screen and (max-width: 800px) {
  .sidebar {
    z-index: 10000000;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  background: transparent
    url("https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png")
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}

.e-grid td.e-active {
  background: #181143;
  color: white !important;
}

.e-grid td.e-active:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.e-grid td.e-active:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.e-rowcell {
  @apply font-medium text-[17px] text-[#181143] border-0 !important;
}

.e-row:hover {
  @apply bg-[#ebeae9] rounded-xl !important;
}

.e-grid tr {
  @apply h-[50px] !important;
}

.e-gridheader {
  @apply border-x-0 border-t-0 border-b-[2px] border-[#E1DFEE] !important;
}

/* .e-grid td button {
  color: white ;
} */

.e-grid td {
  color: #181143 !important;
}

.e-grid td.e-active {
  color: white !important;
}

.e-grid td.e-active:first-child {
  color: #ec1577 !important;
}

.e-grid {
  border-color: #ffffff;
}

.e-grid [class^="e-"] {
  box-sizing: border-box;
  /* border: none; */
}

.e-grid .e-pager {
  border-bottom: rgba(0, 0, 0, 0);
  border-left: rgba(0, 0, 0, 0);
  border-right: rgba(0, 0, 0, 0);
  border-top: 1px solid #e1dfee;
}
/*
select.text-undefined.p-3.w-undefined.hover\:drop-shadow-xl.hover\:bg-undefined {
  @apply md:w-96;
  padding: 0 2rem 0 1rem;
  height: 45px;
  margin-right: 0%;
}
*/
.formselect {
  @apply border-2 border-[#E1DFEE] rounded-xl h-14 w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full p-3 shadow-xl text-[#AAA6C3];
}

.formselect:hover {
  @apply border-[#181143];
}
.mt-2.p-2.md\:p-2.bg-white.rounded-3xl {
  padding-top: 0.75rem;
  padding-right: 0rem;
  padding-bottom: 0.75rem;
  padding-left: 0rem;
}

.checkbox-permissions input[type="checkbox"] {
  position: relative;
  left: 0;
  top: 0;
  cursor: pointer;
  border-radius: 100%;
  height: 1vw;
  width: 1vw;
}

.checkbox-permissions input:hover {
  outline: none;
}

.checkbox-permissions input:focus {
  outline: none;
}

span.e-headertext {
  @apply text-base sm:text-lg text-[#AAA6C3];
}

/* tr.e-row [class^='e-']:focus {
  font-size: 1.20vw;
  font-weight: 500;
  color: #E1DFEE!important;
}
tr.e-row [class^='e-']:active {
  font-size: 1.20vw;
  font-weight: 500;
  color: #E1DFEE!important;
}

tr.e-row [class^='e-'] {
  font-size: 1.20vw;
  font-weight: 500;
  color: #181143!important;
} */

.permission-checkbox:checked ~ .checked-1 {
  @apply text-opacity-100;
}

.all-permissions-checkbox:checked ~ .checked-2 {
  @apply text-opacity-100;
}

.all-permissions-checkbox:disabled {
  @apply bg-white;
  @apply border-[#E1DFEE];
}

.all-permissions-checkbox:hover:disabled {
  @apply outline-none;
}

.all-permissions-checkbox:disabled ~ .disabled-2 {
  @apply text-opacity-100;
}

.permission-checkbox:disabled ~ .disabled-1 {
  @apply text-opacity-100;
}

.permission-checkbox:disabled {
  @apply bg-white;
  @apply border-[#E1DFEE];
}

.permission-checkbox:hover:disabled {
  @apply outline-none;
}

.permission-checkbox:disabled ~ .checked-1 {
  @apply text-opacity-0;
}

.formInput {
  @apply border-2 border-[#E1DFEE] rounded-xl h-14 w-full sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full p-3 text-[#AAA6C3];
}

.formStyle {
  @apply flex flex-col justify-between gap-3 p-5 md:p-5 w-2/3;
}

.formLabel {
  @apply text-center  text-[#181143] pt-3  sm:text-2xl sm:pt-1;
}

.addButton {
  @apply bg-[#EC1577] rounded-[25px] text-[#fff] h-[37px];
}
.essentialInput {
  @apply bg-transparent border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-[0.8rem] block sm:w-11/12 dark:placeholder-gray-400 h-[2.8rem] p-2;
}

.essentialSelect {
  @apply bg-[#E1DFEE] border border-transparent text-gray-900 text-sm rounded-[0.8rem] block w-full sm:w-11/12 dark:placeholder-gray-400 h-10 p-2 shadow-lg;
}
.essentialSearch:focus {
  @apply outline-[#a7a7ac] !important;
}

.essentialLabel {
  @apply text-[#181143] text-[10px]  sm:text-lg font-medium mr-2 mt-2 mb-2;
}

.formButton {
  @apply mt-[3%] bg-[#181143] text-white h-9 w-20 rounded-md;
}

.roleButton {
  @apply mt-[3%] ml-[15%] bg-[#181143] text-white h-9 w-20 rounded-md;
}
.essentialSearch {
  @apply bg-[#E1DFEE] text-gray-900 text-sm rounded-[0.8rem] block dark:placeholder-gray-400 h-10 p-2 w-full;
}

.essentialDescription {
  @apply w-full border-2 border-[#AAA6C3] rounded-[0.8rem] p-4;
}

.ql-toolbar {
  @apply border-0 border-[#AAA6C3]  !important;
}

.ql-container {
  @apply border-0 border-[#AAA6C3] rounded-sm h-[7rem] !important;
}

.ql-editor {
  @apply border-2 border-[#AAA6C3] rounded-xl h-[7rem] !important;
}

.tags-input-container {
  @apply border-2 rounded-lg border-[#AAA6C3] p-3 w-[97%] h-[100%] flex items-center flex-wrap gap-1;
}

.tag-input {
  @apply inline-block p-2 bg-[#EC1577] rounded-xl space-x-2;
}

.close {
  @apply h-4 w-4 text-white flex justify-center items-center mt-[0.4rem] !important;
}

.SubTitle {
  @apply text-[#181143]  sm:text-[1.5rem] text-[1.3rem] font-bold mr-2 mt-2 mb-2;
}

.deliveryInput {
  @apply rounded-2xl p-5 h-[2.7rem];
}

.deliveryCheckbox {
  @apply h-7 w-7 rounded-full outline-0 appearance-none checked:bg-[#181143] scale-75 border-1 border-[#181143] disabled:pointer-events-none disabled:cursor-not-allowed;
}

.optionCheckbox {
  @apply h-6 w-6 rounded-full outline-0 appearance-none checked:bg-[#181143] scale-75 border-1 border-[#181143];
}

.optionCheckbox:checked ~ .checked {
  @apply text-opacity-100;
}

.priceInput {
  @apply rounded-[1vw] p-[1vw] h-[2vw]  w-[80%] text-[1vw] hover:outline-0  focus:outline-transparent focus:shadow-transparent  !important;
}

.deliveryCheckbox:checked ~ .checked {
  @apply text-opacity-100;
}

.packProductInput {
  @apply rounded-xl p-5 h-[2.7rem] w-[80%] bg-transparent hover:outline-0  focus:outline-transparent;
}

.groupAttributeLabel {
  @apply text-[#181143] sm:text-lg font-medium;
}

.groupAttributeInput {
  @apply rounded-xl p-5 h-[2.7rem] block hover:outline-0 focus:outline-transparent focus:shadow-transparent !important;
}

.colorInput {
  @apply bg-white rounded-l-2xl w-1/4 px-4 h-10 hover:outline-0 focus:outline-transparent focus:shadow-transparent;
}

.textureInput {
  /* @apply bg-white w-1/3 px-4 h-10 hover:outline-0 focus:outline-transparent focus:shadow-transparent; */
}
/* rounded-l-2xl */
.searchCategoriesInput:focus ~ .searchCategories {
  @apply opacity-100 w-full h-40;
}

.shopSelect {
  @apply bg-[#E1DFEE] border border-transparent text-[#EC1577] text-sm rounded-[0.8rem] block w-full  dark:placeholder-gray-400 h-10 p-2 shadow-lg;
}

.createProductInput {
  @apply bg-transparent border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-[0.8rem] block sm:w-[95%] dark:placeholder-gray-400 h-[2.8rem] p-2;
}

.createProductLabel {
  @apply text-[#181143] w-[8rem] md:w-full text-[10px]  sm:text-lg font-medium mr-2 mt-2 mb-2;
}

.CreateProductSelect {
  @apply bg-[#E1DFEE] border border-transparent text-gray-900 text-sm rounded-[0.8rem] block w-[90%] sm:w-[95%] dark:placeholder-gray-400 h-10 p-2 shadow-lg;
}

.variations {
  @apply flex justify-between items-center cursor-pointer bg-[#E1DFEE] border border-transparent text-gray-900 text-sm rounded-[0.8rem] w-full sm:w-11/12 dark:placeholder-gray-400 h-10 px-2 shadow-lg;
}
.multiSelectInput {
  @apply bg-transparent border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-[0.8rem] block sm:w-11/12 dark:placeholder-gray-400 h-[2.8rem] p-2;
}

.addButtonBlue {
  @apply bg-[#181143] rounded-[25px] text-[#fff] h-[37px] shadow-lg;
}

.addButtonGrey {
  @apply bg-[#E1DFEE] rounded-[25px] text-[#fff] h-[37px] shadow-lg;
}

.whiteSelect {
  @apply bg-[#fff] border-2 border-[#AAA6C3] text-[#AAA6C3] text-sm rounded-[0.8rem] block w-full sm:w-11/12 dark:placeholder-gray-400 h-[2.4vw] p-2;
}

.whiteSelectWithoutBorder {
  @apply bg-[#fff]  border-transparent text-[#AAA6C3] text-[1vw] rounded-[0.8vw] block w-full sm:w-11/12 dark:placeholder-gray-400 h-[2.4vw] p-2 hover:border-0 hover:outline-none;
}

.crudButton {
  @apply bg-transparent !important;
}

.whiteSelectTextarea {
  @apply bg-[#fff] border-2 border-[#AAA6C3] text-[#AAA6C3] text-sm rounded-[0.8rem] block w-full sm:w-11/12 dark:placeholder-gray-400 h-[10rem] p-2;
}

.e-headertext {
  @apply flex justify-center text-[14px] text-[#AAA6C3] !important;
}

/* .crud-data {
  @apply text-[12px] text-[#181143];
} */

.e-rowcell {
  @apply text-[12px] text-[#181143] relative !important;
}

.e-grid td.e-active .hidden_preview_cart {
  @apply opacity-100 transform -translate-x-1/2 transition duration-[500ms] absolute z-40 bg-[#EC1577] h-full bottom-0 left-[95.9%] rounded-l-xl w-full;
}

/* .e-grid td.e-active:last-child {
  @apply p-0 m-0 !important;
} */
.fade-in {
  animation: fadeIn 2s;
  opacity: 1;
}
.sft-shadow {
  filter: drop-shadow(0 0 0px rgb(0 0 0 / 0.04))
    drop-shadow(0px 3px 10px rgb(0 0 0 / 0.33));
}

.form-label {
  @apply text-[1.2vw] text-[#181143] font-semibold flex flex-row;
}

.phone_box {
  @apply bg-white  text-gray-900 text-sm rounded-[0.9rem]  sm:w-[80%] dark:placeholder-gray-400 h-[2.8rem] flex flex-row;
}

.phone_input {
  @apply bg-white w-[80%] text-[14px] text-[#181143]  h-full rounded-r-[0.8rem] p-2 hover:outline-0  focus:outline-transparent focus:shadow-transparent !important;
}

.phone_call_prefix_box {
  @apply w-[10%] text-center self-center  text-[14px] flex justify-evenly;
}

.phone_call_prefix {
  @apply text-[#EC1577] text-[16px] text-center self-center;
}

.error-box-phone {
  @apply w-[10%] bg-[#EC1577] rounded-r-[0.8rem];
}

.error-box {
  @apply w-[4rem] bg-[#EC1577] rounded-r-[0.8rem];
}
.cone {
  margin-top: -20px;
  height: 0px;
  width: 0px;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-top: 35px solid #ec1577;
  z-index: 1;
  @apply absolute w-3 -left-[5%] rotate-45 -bottom-[28%];
}

.error-message-box-phone {
  @apply opacity-0 min-w-[15rem] h-0 p-2  absolute rounded-xl  bg-[#EC1577] hover:max-w-[20rem] hover:h-auto hover:transform hover:-translate-y-[3.5rem] hover:translate-x-[4.5rem]  hover:opacity-100 hover:transition hover:duration-[1000ms] flex justify-center;
}

.text_input_box {
  @apply bg-white  text-gray-900 text-sm rounded-[0.9rem]  sm:w-[80%] dark:placeholder-gray-400 h-[2.8rem] flex flex-row justify-between;
}

.form-Textarea-shifti-white {
  @apply w-[80%] rounded-xl border-none p-2 text-[#181143] text-[14px];
}

.form-Input-Shifti-white {
  @apply bg-white w-[100%] text-[14px] text-[#181143]  h-full rounded-[0.8rem] p-2 hover:outline-0  focus:outline-transparent focus:shadow-transparent !important;
}

.error-message-box {
  @apply opacity-0 min-w-[15rem] h-0 p-2  absolute rounded-xl  bg-[#EC1577] hover:max-w-[20rem] hover:h-auto hover:transform hover:-translate-y-[3.5rem] hover:translate-x-[4.5rem]  hover:opacity-100 hover:transition hover:duration-[1000ms] flex justify-center items-center;
}

.form-Input-Shifti-white-disabled {
  @apply bg-[#E1DFEE] w-[100%] text-[14px] text-[#181143]  h-full rounded-[0.8rem] p-2 hover:outline-0  focus:outline-transparent focus:shadow-transparent !important;
}

.radio-input-right:checked ~ p {
  @apply text-white left-[3.2rem];
}

.radio-input-left:checked ~ p {
  @apply text-white left-[1rem];
}

.radio-input-shifti:checked ~ div {
  @apply opacity-100;
}

.priceInput:disabled {
  @apply cursor-no-drop;
}


.animate-grow {
	animation: grow 0.5s ease forwards;
	transform-origin: center;
  }
  
  @keyframes grow {
	0% {
	  transform: scale(0); /* Start with hidden (scale 0) */
	}
	100% {
	  transform: scale(1); /* Grow to scale 1.25 (125%) */
	}
  }