.loginLayout {
  display: flex;
  height: 100vh;
}
.sider {
  background: url("../../../data/register2.png") no-repeat 0% 50px;
  position: fixed;
  top: 0;
  left: 0;
  height: 120vh;
  width: 50vw;
  background-color: #181144;
  background-size: contain !important;
}
.formLayout {
  width: 50vw;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75rem;
  padding: 1.25rem;
  width: 66.6%;
}
/* form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding-top: 50px;
    padding-bottom: 60px;
    margin: auto;
  }
  */
.formTitle {
  font-size: 45px;
  color: #181143;
  font-weight: 800;
  margin-bottom: 35px;
}
/* input {
    border-radius: 24px;
    border: 1px solid #1811432e;
    height: 55px;
    width: 500px;
    outline: #181143;
    padding: 0 15px;
    text-align-last: center;
    text-align: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
  }
  input::placeholder {
    color: #18114366;
    text-align-last: center;
    text-align: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
  }
  */
/* .selectForm:hover,
input:hover {
  outline: 1px solid #181143;
}
.selectForm:focus,
input:focus {
  outline: 1px solid #181143;
} */
.selectForm {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' standalone='no'?%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 20010904//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg version='1.0' xmlns='http://www.w3.org/2000/svg' width='10.000000pt' height='6.000000pt' viewBox='0 0 10.000000 6.000000' preserveAspectRatio='xMidYMid meet'%3E%3Cg transform='translate(0.000000,6.000000) scale(0.100000,-0.100000)' fill='%23000000' stroke='none'%3E%3Cpath d='M0 53 c0 -5 11 -20 25 -33 l25 -23 25 23 c14 13 25 28 25 33 0 6 -11 0 -25 -13 l-25 -23 -25 23 c-14 13 -25 19 -25 13z'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 22px;
  border-radius: 24px;
  border: 1px solid #1811432e;
  height: 55px;
  width: 500px;
  outline: #181143;
  padding: 0 15px;
  text-align-last: center;
}
.textareaForm {
  height: 180px;
}
inputSubmission {
  color: white;
  background-color: #181143;
  font-size: 20px;
  font-weight: 800;
}
.eye {
  float: right;
  margin-left: -38px;
  margin-top: 12px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  color: #18114380;
}
/*
  .passwordContainer {
    width: 100%;
    text-align: center;
    position: relative;
  }
  */
.input::placeholder {
  color: #18114366;
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}
.input:hover {
  outline: 1px solid #181143;
}
.input:focus {
  outline: 1px solid #181143;
}
.inputSubmission {
  color: white;
  background-color: #181143;
  font-size: 20px;
  font-weight: 800;
}
.formSign_in {
  color: #ec1577;
  cursor: pointer;
  font-weight: 300;
  text-decoration-line: underline;
}
.sftLoginFooter {
  display: flex;
  justify-content: space-between;
  width: 97%;
}
.formInput {
  display: block;
  text-align: center;
}
/* @media only screen and (max-width: 600px) {
      .selectForm,
      input {
        width: 80vw;
      }
      .formTitle {
        font-size: 7vw;
        margin-bottom: 20px;
      }
      .form {
        gap: 8px;
      }
    } */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sider {
    width: 30vw;
  }
  .formLayout {
    width: 70vw;
  }
  .form {
    width: 90%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .sider {
    display: none;
  }
  .formLayout {
    width: 100vw;
    margin: auto 20px;
    justify-content: center;
  }
  .form {
    width: 90%;
  }
  .formTitle {
    font-size: 6vw;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .sider {
    display: none;
  }
  .formLayout {
    width: 100vw;
    margin: auto 20px;
    justify-content: center;
  }
  .form {
    width: 90%;
  }
  .formTitle {
    font-size: 20px;
  }
}
